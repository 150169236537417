import React from 'react';
import Header from "../Header/Header"
import Main from "../Main/Main";
import ProvablyFairnessModal from "../Modal/ProvablyFairnessModal";
import TermAndConditionsModal from "../Modal/TermAndConditionsModal";
import HonestyModal from "../Modal/HonestyModal";
import MobileMenu from "../MobileMenu/MobileMenu";
import MobileAllBetsModal from "../Modal/MobileAllBetsModal";
import MobileHistoryModal from "../Modal/MobileHistoryModal";
import MobileSettingsModal from "../Modal/MobileSettingsModal";
import MobileTournamentModal from "../Modal/MobileTournamentModal";
import PromoModal from "../Modal/PromoModal";
import MobileTournamentHeader from "../MobileTournamentHeader/MobileTournamentHeader";

function GameScreen() {
    return <div className={`gameScreen gameScreen__wrapper`}>
        <Header/>
        <MobileTournamentHeader/>
        <Main/>
        <MobileMenu/>
        <ProvablyFairnessModal/>
        <TermAndConditionsModal/>
        <HonestyModal/>
        <MobileAllBetsModal/>
        <MobileHistoryModal/>
        <MobileTournamentModal/>
        <MobileSettingsModal/>
        <PromoModal/>
    </div>
}

export default GameScreen
