import {useSelector} from "react-redux";
import Modal from "./Modal";
import ModalBody from "./ModalBody/ModalBody";
import ModalHeader from "./ModalHeader/ModalHeader";
import Promo from "../Promo/Promo";

function PromoModal() {
    const isOpen = useSelector(state => (state.user.modal.isPromoModalOpen));
    const isMobile = useSelector(state => (state.user.isMobile));
    const isMobileLandscape = useSelector(state => (state.user.isMobileLandscape));

    return (
        <Modal isOpen={isOpen}>
            {(isMobile && !isMobileLandscape) && <ModalHeader/>}
            <ModalBody>
                <Promo/>
            </ModalBody>
        </Modal>
    )
}

export default PromoModal