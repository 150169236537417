import TournamentTable from "../Tables/TournamentTable";
import RoundTable from "./RoundTable";
import BetTable from "./BetTable";

const mapData = {
    leaderBoard: TournamentTable,
    round: RoundTable,
    bet: BetTable
}

export default mapData;