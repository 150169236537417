import LinearLoader from "./linearLoader";
import {useTranslation} from "react-i18next";
function LoaderGlobal({isActive, notification}) {
    const {t} = useTranslation();
    return(
        <div className={`global-loader ${!isActive ? 'global-loader__invisible' : ''}` } >
            <div className="global-loader__wrapper">
                <div className="global-loader__img">
                    <img src="/img/global-loader.gif" alt="global-loader" />
                    <LinearLoader />
                </div>
                {(notification.labelFor === 'loader' && notification.message) && <div className="global-loader__error-msg">{t(`notification.${notification.message}`)}</div>}
            </div>
        </div>
    )
}
export default LoaderGlobal