import {useSelector} from "react-redux";
import Modal from "./Modal";
import ModalBody from "./ModalBody/ModalBody";
import ModalHeader from "./ModalHeader/ModalHeader";
import TableContainer from "../TableContainer/TableContainer";

function MobileHistoryModal() {
    const isHistoryModalOpen = useSelector(state => (state.user.modal.isHistoryModalOpen));
    const isMobile = useSelector(state => (state.user.isMobile));
    const isMobileLandscape = useSelector(state => (state.user.isMobileLandscape));
    const roundHistory =  useSelector(state => state.game.finishedRounds);
    const highestMultiplierRounds =  useSelector(state => state.game.highestMultiplierRounds);

    return ((isMobile && isHistoryModalOpen) &&
        <Modal isOpen={isHistoryModalOpen}>
            {(isMobile && !isMobileLandscape) && <ModalHeader/>}
            <ModalBody>
                <TableContainer
                    tabItems={['tabs.round_history', 'tabs.round_history_top']}
                    tableData={[roundHistory, highestMultiplierRounds]}
                />
            </ModalBody>
        </Modal>
    )
}

export default MobileHistoryModal