import {useDispatch} from "react-redux";
import {userCloseModal} from "../../../../actions/user/actions";
import {useTranslation} from "react-i18next";

function ModalHeader({children}) {
    const dispatch = useDispatch();
    const {t} = useTranslation()

    const backToGame = () => {
        dispatch(userCloseModal())
    }

    return (
        <div className="popup__header">
            {!children ? <>
                <div className=" popup__header__back" onClick={backToGame}>
                    <i className="popup__header__back--icon"/>
                    <span className="popup__header__back--text">{t("mobile_menu.back_to_menu")}</span>
                </div>
            </> : {...children}
            }

        </div>
    )
}

export default ModalHeader;