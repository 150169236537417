import React from 'react';

const InputChanger = React.memo((props) => {
    const {
        minValue,
        maxValue,
        step,
        inputValue,
        hasDoubleButton,
        handleChange,
        disabled
    } = props;

    const calculateNewAmount = (type) => {
        let currentAmount = parseFloat(inputValue);
        let resultAmount;
        switch (type) {
            case 'double':
                resultAmount = currentAmount * 2 < maxValue ? (currentAmount * 2).toFixed(2) : maxValue;
                break;
            case 'plus':
                resultAmount = currentAmount + parseFloat(step) < maxValue ? (currentAmount + parseFloat(step)).toFixed(2) : maxValue;
                break;
            case 'minus':
                resultAmount = currentAmount - parseFloat(step) > minValue ? (currentAmount - parseFloat(step)).toFixed(2) : minValue;
                break;
            default:
                resultAmount = currentAmount;
                break;
        }
        return handleChange(resultAmount);
    };

    const changeInputValue = (e) => {
        const value = e.target.value;
        const regex = /^(\d+(\.\d{0,2})?)?$/;
        const regexPattern = /^(0\.00|0[1-9]|00)$/;
        if (!regex.test(value)) return;
        let resultValue = regexPattern.test(value) ? minValue : value > maxValue ? maxValue : value;
        handleChange(resultValue);
    };
    const isButtonDisabled = (value) => {
        return parseFloat(inputValue) === value;
    };

    const setMinValue = () => {
        if (!parseFloat(inputValue) || parseFloat(inputValue) < parseFloat(minValue)) {
            handleChange(minValue)
        } else {
            handleChange(parseFloat(inputValue).toFixed(2))
        }
    };

    return <div className={`controlItem__left__amount ${disabled}`}>
        <div className="controlItem__left__amount--margin">
            <button
                className="controlItem__left__amount--btn"
                onClick={() => calculateNewAmount('minus')}
                disabled={isButtonDisabled(minValue)}>
            </button>
            <input
                className="controlItem__left__amount--input"
                max={maxValue}
                value={inputValue}
                onChange={changeInputValue}
                onBlur={setMinValue}
                placeholder={`${minValue}-${maxValue}`}
            />
            <button
                className="controlItem__left__amount--btn plus"
                onClick={() => calculateNewAmount('plus')}
                disabled={isButtonDisabled(maxValue)}>
            </button>
        </div>
        {
            hasDoubleButton &&
            <button
                className="controlItem__left__amount--double"
                onClick={() => calculateNewAmount('double')}
                disabled={isButtonDisabled(maxValue)}
            >
                x2
            </button>
        }
    </div>
}, (prevProps, nextProps) => {
    return (prevProps.inputValue === nextProps.inputValue) && (prevProps.disabled === nextProps.disabled)
})

export default InputChanger