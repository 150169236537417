import CloseComponent from "../CloseComponent/CloseComponent";
import {useDispatch} from "react-redux";
import {userCloseModal} from '../../../actions/user/actions'

function Modal({children, isOpen}) {
    const dispatch = useDispatch();

    return (isOpen &&
        <div className="popup popup--opened">
            <div className="popup__overlay" onClick={() => dispatch(userCloseModal())}></div>
            <div className="popup__wrapper">
                <CloseComponent handleClose={() => dispatch(userCloseModal())}/>
                <div className="popup__content">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal;