import React from "react";
import {convertAmountByUserRate} from "../../../../utilities/utilities";
import {userCloseModal, userOpenHonestyModal} from "../../../../actions/user/actions";

export const createTableRow = (role, tableName, row, rowIndex, userRateValue, dispatch = () => {
}) => {
    let externalClassname = `table__body--row-${tableName}`;

    if (role === "bet") externalClassname = row.cells.table_win ? `table__body--row-${tableName}-green` : `table__body--row-${tableName}-red`
    if (role === "leaderBoard") {
        externalClassname = `table__body--row-${tableName} table__body--row-${tableName}${row.selected ? "--selected" : ''}`
    }


    return <div className={`table__body--row ${externalClassname}`} key={rowIndex}>
        {Object.keys(row.cells).map((key, columnIndex) => createTableCell(key, columnIndex, row, rowIndex, userRateValue, dispatch))}
    </div>
};
export const createTableCell = (key, index, row, rowIndex, userRateValue, dispatch = () => {
}) => {
    const cellValue = row.cells[key];

    const openHonestyModal = (resultHash, salt, odd, roundId) => {
        dispatch(userCloseModal())
        dispatch(userOpenHonestyModal({hash: resultHash, salt, odd, roundId}))
    }

    switch (key) {
        case 'table_date':
            return <div
                key={index}
                className="table__body--row--item  item--date"
                data-full_date={`${new Date(cellValue).toLocaleDateString('en-GB')} ${new Date(cellValue).toLocaleString('en-GB', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false
                })}`}
            >
                <span>
                    {`${new Date(cellValue).toLocaleString('en-GB', {
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false
                    })}`}
                </span>
            </div>

        case 'table_odd':
            return <div key={index} className="table__body--row--item item--odd">
                {cellValue}
            </div>

        case 'table_bet':
        case 'table_win':
            return <div key={index} className={`table__body--row--item ${key === "table_win" ? "item--win" : ''}`}>
                {convertAmountByUserRate(cellValue, userRateValue)}
            </div>

        case 'table_playerId':
            return <div key={index} className="table__body--row--item item--tournament">
                <p>{!row.selected ? rowIndex + 1 : row.cells["table_place"]}</p>
                <p className='flex-grow'>{cellValue}</p>
            </div>

        case 'table_place':
            return
        case 'table_prize':
            return <div className={`table__body--row--item ${cellValue !== 0 ?  "item--win": "" }`} key={index}>
                {convertAmountByUserRate(cellValue, userRateValue)}
            </div>
        case 'table_points':
            return <div key={index} className="table__body--row--item">
                {cellValue === 0 ? '-' : cellValue}
            </div>
        case 'table_honesty':
            const {resultHash, salt} = row.honestyProof;
            const odd = row.cells["table_odd"];
            const roundId = row.roundId;
            return <div key={index} className={`table__body--row--item item--honestyIcon`}
                        onClick={() => openHonestyModal(resultHash, salt, odd, roundId)}>
                <img src="/img/icon-honesty.svg" alt="honesty-icon"/>
            </div>
        default:
            return <div key={index} className="table__body--row--item">
                {cellValue}
            </div>

    }
}
