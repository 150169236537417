import React from 'react';
import {useSelector} from "react-redux";

const Background = ({isMoving}) => {
    const isAnimationActive = useSelector(state => state.game.isAnimationActive);
    return <>
        {
            <img
                src='/img/background.png'
                alt="Background"
                className={`animation__background-image  ${isMoving ? 'animate' : 'stop-animate'} ${!isAnimationActive ? 'opacity-none' : ''}`}
            />
        }
    </>
};

export default Background;
