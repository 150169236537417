import {useCallback, useState} from "react";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {closeBetController, userToggleAutoBet, userToggleAutoCashOut} from "../../../../actions/user/actions";
import BetButton from "./BetButton/BetButton";
import RangeInput from "./RangeInput/RangeInput";
import CloseComponent from "../../CloseComponent/CloseComponent";
import InputChanger from "./InputChanger/InputChanger";
import Switcher from "../../Switcher/Switcher";
import AmountButtons from "./AmountButtons/AmountButtons";

function Controller({controllerData}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {minOdd, maxOdd} = useSelector(state => (state.user.oddRange));
    const {minBet, maxBet, defaultBetValue} = useSelector(state => (state.user.betRange));
    const [autoCashOutValue, setAutoCashOutValue] = useState(minOdd);
    const [amount, setAmount] = useState(defaultBetValue.toFixed(2));

    const handleAmountChange = useCallback((resultAmount) => {
        setAmount(resultAmount);
    }, [setAmount]);

    const handleAutoCashOutChange = useCallback((resultAmount) => {
        setAutoCashOutValue(resultAmount);
    }, [setAutoCashOutValue]);

    return (
        <div className="controlItem controlItem__wrapper">
            {controllerData.hasCloseBtn && !controllerData.roundId && <CloseComponent handleClose={() => dispatch(closeBetController({id: controllerData.id}))}/>}

            <div className="controlItem__left">
                <InputChanger
                        minValue={minBet.toFixed(2)}
                        maxValue={maxBet}
                        inputValue={amount}
                        step={minBet}
                        handleChange={handleAmountChange}
                        hasDoubleButton={true}
                        disabled={controllerData.transactionId && 'controller--disabled'}
                    />
                    <RangeInput
                        minBet={minBet}
                        maxBet={maxBet}
                        amount={amount}
                        handleChange={handleAmountChange}
                        disabled={controllerData.transactionId && 'controller--disabled'}
                    />
                    <AmountButtons
                        handleClick={handleAmountChange}
                        currentBet={amount}
                        minBet={minBet}
                        maxBet={maxBet}
                        disabled={controllerData.transactionId && 'controller--disabled'}
                    />

                <div className="controlItem__left--autoGame">
                    <Switcher
                        onToggle={() => dispatch(userToggleAutoBet({bettorId: controllerData.id}))}
                        isActive={controllerData.autoBet}
                    />
                    <label htmlFor="switch"
                           className="controlItem__left--autoGame--label">{t('control.autoplay')}</label>
                </div>
            </div>
            <div className="controlItem__right">
                <BetButton
                    controllerData={{
                        ...controllerData,
                        betAmount: amount,
                        autoCashOutValue
                    }}
                />
                <div className={`controlItem__right__cashOut ${(controllerData.transactionId) ? 'auto-cashOut-disabled' : ''}`}>
                    <Switcher
                        onToggle={() => dispatch(userToggleAutoCashOut({bettorId: controllerData.id}))}
                        isActive={controllerData.autoCashOut}
                        direction={'vertical'}
                    />
                    <div
                        className={`controlItem__right__cashOut--amount ${(!controllerData.autoCashOut || controllerData.transactionId) ? "controlItem__right__cashOut--amount--disabled" : ''}`}>
                        <label>{t('control.cashOut')}</label>
                        <InputChanger
                            minValue={minOdd}
                            maxValue={maxOdd}
                            step={0.01}
                            inputValue={autoCashOutValue}
                            handleChange={handleAutoCashOutChange}
                            hasDoubleButton={false}
                            disabled={controllerData.transactionId && 'controller--disabled'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Controller