import {useDispatch, useSelector} from "react-redux";
import Modal from "./Modal";
import ModalBody from "./ModalBody/ModalBody";
import ModalHeader from "./ModalHeader/ModalHeader";
import Switcher from "../Switcher/Switcher";
import {gameToggleAnimation} from "../../../actions/game/actions";
import {useTranslation} from "react-i18next";
import {userResetBetControllers, userToggleDemo} from "../../../actions/user/actions";

function MobileSettingsModal() {
    const isSettingsModalOpen = useSelector(state => (state.user.modal.isSettingsModalOpen));
    const isDemoActive = useSelector(state => (state.user.isDemoActive));
    const betControllers = useSelector(state => (state.user.betControllers));
    const isAnimationActive = useSelector(state => (state.game.isAnimationActive));
    const isMobile = useSelector(state => (state.user.isMobile));
    const isMobileLandscape = useSelector(state => (state.user.isMobileLandscape));
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const toggleDemo = () => {
        const isBetExists = Object.values(betControllers).filter(c => c.roundId !== null).length;
        if(!isDemoActive && isBetExists) return;
        dispatch(userResetBetControllers())
        dispatch(userToggleDemo())
    }

    return ((isMobile && isSettingsModalOpen) &&
        <Modal isOpen={isSettingsModalOpen}>
            {(isMobile && !isMobileLandscape) &&
                <ModalHeader/>
            }
            <ModalBody>
                <ul className="popup__mobile__settings">
                    <li className="popup__mobile__settings--item">
                        <label>{t("dropdown.animation")}</label>
                        <Switcher onToggle={() => dispatch(gameToggleAnimation())} isActive={isAnimationActive}/>
                    </li>
                    <li className="popup__mobile__settings--item">
                        <label>{t("dropdown.demo")}</label>
                        <Switcher onToggle={() => toggleDemo()} isActive={isDemoActive}/>
                    </li>
                </ul>
            </ModalBody>
        </Modal>
    )
}

export default MobileSettingsModal