import React from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Multiplier = () => {
    const {t} = useTranslation();
    const {multiplier, crashedMultiplier, sprite, bettingTime} = useSelector(state => state.game.round);
    const isAnimationActive = useSelector(state => state.game.isAnimationActive);

    return <>
        {
            (!crashedMultiplier && multiplier !== 0 && isAnimationActive) &&
            <div className="animation__multiplier">{multiplier}</div>
        }
        {
            (!bettingTime && crashedMultiplier && sprite === 18 && isAnimationActive) &&
            <div className="animation__multiplier animation__multiplier--crashed">
                <div className="animation__multiplier--crashed--result">{t('animation.result')}</div>
                <div className="animation__multiplier--crashed--odd">{crashedMultiplier}</div>
            </div>
        }
        {
            (!crashedMultiplier && multiplier !== 0 && !isAnimationActive) && <>
                <div className="animation__multiplier__disabled--title">{t('animation.multiplier')}</div>
                <div className="animation__multiplier__disabled">{multiplier}</div>
            </>
        }
        {
            (!bettingTime && crashedMultiplier && sprite && !isAnimationActive) && <>
                <div className="animation__multiplier__disabled--title">{t('animation.result')}</div>
                <div className="animation__multiplier__disabled  animation__multiplier__disabled--crashed">{crashedMultiplier}</div>
            </>
        }
    </>
};

export default Multiplier;
