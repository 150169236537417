import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {
    userOpenPromoModal,
    userOpenProvablyFairnessModal,
    userOpenTermAndConditionModal,
    userResetBetControllers,
    userToggleDemo,
} from '../../../actions/user/actions'
import DropDown from "../DropDown/DropDown";
import Switcher from "../Switcher/Switcher";
import {gameToggleAnimation} from "../../../actions/game/actions";
import MobileMenuLogo from "../MobileMenuLogo/MobileMenuLogo";

function Header() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isAnimationActive = useSelector(state => state.game.isAnimationActive)
    const user = useSelector(state => state.user);
    const balance = user.isDemoActive ? user.demoBalance : user.balance;
    const currencyCode = user.isDemoActive ? user.demoCurrency : user.currencyCode;
    const hasTournament = useSelector(state => state.tournament.hasTournament)

    const toggleDemo = () => {
        const isBetExists = Object.values(user.betControllers).filter(c => c.roundId !== null).length;
        if (!user.isDemoActive && isBetExists) return;
        dispatch(userResetBetControllers());
        dispatch(userToggleDemo());
    }
    return (
        <div className="header header__wrapper">
            <div className="header__button">
                <div className="header__button-item" onClick={() => dispatch(userOpenProvablyFairnessModal())}>
                    <span className="header__button-icon"></span>
                    <p className="header__button-text">{t(`header.proof`)}</p>
                </div>
                <div className="header__button-item" onClick={() => dispatch(userOpenTermAndConditionModal())}>
                    <span className="header__button-icon"></span>
                    <p className="header__button-text">{t(`header.rules`)}</p>
                </div>
            </div>
            <div className="header__mobile header__mobile--balance">{`${balance.toFixed(2)} ${currencyCode}`}</div>
            <div className="header__center">
                <div className="header__center--logo"></div>
            </div>
            <MobileMenuLogo/>
            <div className="header__user">
                <div className="header__user__wrapper">
                    {hasTournament &&
                        <div className="header__user__tournament" onClick={() => dispatch(userOpenPromoModal())}>
                            <img src="/img/tournament-icon.svg" alt='tournament-icon' className="header__user__tournament--icon"/>
                        </div>
                    }
                    <img className="header__user__avatar" src="/img/user-avatar.png" alt="user avatar"/>
                    <label>
                        <p className="header__user__id">ID {user.platformId}</p>
                        <p className="header__user__nickname">{user.userName}</p>
                        <p className="header__user__balance">{`${balance.toFixed(2)} ${currencyCode}`}</p>
                    </label>
                </div>
                <DropDown items={[
                    {
                        component: <><label>{t(`dropdown.animation`)}</label><Switcher
                            onToggle={() => dispatch(gameToggleAnimation())} isActive={isAnimationActive}/></>
                    },
                    {
                        component: <><label>{t(`dropdown.demo`)}</label><Switcher
                            onToggle={() => toggleDemo()} isActive={user.isDemoActive}/></>
                    },
                ]}/>
            </div>
        </div>
    )
}

export default Header;