import {userCloseModal, userOpenMobileMenu} from '../../../actions/user/actions';
import {useDispatch} from "react-redux";

function MobileMenuLogo() {

    const dispatch = useDispatch()

    const openMobileMenu = () => {
        dispatch(userCloseModal())
        dispatch(userOpenMobileMenu())
    }

    return (
        <div className="header__mobile" onClick={openMobileMenu}>
            <i className="header__mobile--settings"/>
        </div>
    )
}

export default MobileMenuLogo