export const BackgroundElements = [
    {name: 'dog.png', className: 'moving-z-left', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'cow.png', className: 'moving-z', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'human.png', className: 'moving-y', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'nut.png', className: 'moving-z-left', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'nut2.png', className: 'moving-z', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'rocket.png', className: 'moving-y', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'robot.png', className: 'moving-z-left', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'smile.png', className: 'moving-z', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'smilePlanet.png', className: 'moving-y', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'smilePlanet2.png', className: 'moving-z', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'snail.png', className: 'moving-z-left', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
]

