import React from 'react';

const RangeInput = React.memo((props) => {
    const {
        minBet,
        maxBet,
        amount,
        handleChange,
        disabled
    } = props;
    const handleRangeChange = (value) => {
        let parseValue= parseFloat(value)
        return handleChange(parseValue.toFixed(2))
    }

    return <div className={`controlItem__left--slider ${disabled}`}>
        <input
            type="range"
            min={minBet}
            max={maxBet}
            step={minBet}
            value={amount}
            onChange={(e) => handleRangeChange(e.target.value)}
        />
    </div>
}, (prevProps, nextProps) => {
    return (prevProps.amount === nextProps.amount) && (prevProps.maxBet === nextProps.maxBet) && (prevProps.disabled === nextProps.disabled)
})

export default RangeInput