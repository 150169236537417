import Table from "./Table/Table";

function BetTable ({data}) {
    return(
        <div>
            <Table tableData={data} />
        </div>
    )
}

export default BetTable;
