import { combineReducers } from 'redux';
import userReducer from './user';
import gameReducer from './game';
import tournament from './tournament';

const rootReducer = combineReducers({
    user: userReducer,
    game: gameReducer,
    tournament: tournament,
});
export default rootReducer;
