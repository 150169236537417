import {useSelector} from "react-redux";
import Modal from "./Modal";
import ModalBody from "./ModalBody/ModalBody";
import ModalHeader from "./ModalHeader/ModalHeader";
import TableContainer from "../TableContainer/TableContainer";

function MobileAllBetsModal() {
    const isAllBetsModalOpen = useSelector(state => (state.user.modal.isAllBetsModalOpen));
    const isMobile = useSelector(state => (state.user.isMobile));
    const isMobileLandscape = useSelector(state => (state.user.isMobileLandscape));
    const currentRoundBets = useSelector(state => state.game.round.betList);
    const betHistory = useSelector(state => state.game.betHistory);

    return ((isMobile && isAllBetsModalOpen) &&
        <Modal isOpen={isAllBetsModalOpen}>
            {(isMobile && !isMobileLandscape) && <ModalHeader/>}
            <ModalBody>
                <TableContainer
                    tabItems={['tabs.all_bets', 'tabs.my_bets']}
                    tableData={[currentRoundBets, betHistory]}
                />
            </ModalBody>
        </Modal>
    )
}

export default MobileAllBetsModal