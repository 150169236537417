import React, {useMemo} from 'react';
import {useSelector} from "react-redux";

const StarGenerator = React.memo(({count = 60}) => {
    const isAnimationActive = useSelector(state => state.game.isAnimationActive);

    const stars = useMemo(() => (
        Array.from({length: count}, (_, index) => (
            // <span className={`single-star single-star${index + 1}`} key={index}></span>
            <span className="single-star" key={index}/>
        ))
    ), [count]);
    return (
        // <div className="animation__stars">
        //         <div className="star__wrapper star__wrapper--1">
        //             {stars}
        //         </div>
        //         <div className="star__wrapper star__wrapper--2">
        //             {stars}
        //         </div>
        // </div>
        <div className={`animation__stars ${!isAnimationActive ? 'opacity-none' : ''}`}>
                {stars}
        </div>
    );
}, (prevProps, nextProps) => prevProps.count === nextProps.count);

export default StarGenerator;