import GAME from "./types";
export const setLoader = (payload) => {
    return {
        type: GAME.SET_LOADER,
        payload
    }
};

export const gameRoundSetPlayerList = (payload) => {
    return {
        type: GAME.ROUND_PLAYER_LIST,
        payload
    }
};
export const gameRoundSetMultiplier = (payload) => {
    return {
        type: GAME.ROUND_MULTIPLIER,
        payload
    }
};

export const gameRoundSetBettingTime = (payload) => {
    return {
        type: GAME.ROUND_BETTING_TIMER,
        payload
    }
};
export const gameRoundUpdateState = (payload) => {
    return {
        type: GAME.ROUND_UPDATE,
        payload
    }
};
export const gameRoundCrash = (payload) => {
    return {
        type: GAME.ROUND_CRASH,
        payload
    }
};
export const gameUpdateState = (payload) => {
    return {
        type: GAME.UPDATE,
        payload
    }
};
export const gameFinishedRounds = (payload) => {
    return {
        type: GAME.FINISHED_ROUNDS,
        payload
    }
};
export const gameHighestMultipliers = (payload) => {
    return {
        type: GAME.HIGHEST_MULTIPLIERS,
        payload
    };
};
export const gameToggleAnimation = () => {
    return {
        type: GAME.TOGGLE_ANIMATION,
    };
};
export const gameSendNotification = (payload) => {
    return {
        type: GAME.NOTIFICATION,
        payload
    };
};

export const gameResetNotification = () => {
    return {
        type: GAME.RESET_NOTIFICATION,
    };
};



