import {useEffect} from "react";

export function useOutsideClickHandler(ref, handler, handle = false) {
    useEffect(() => {
        function handleClickOutside(event) {
            !ref.current?.contains(event.target) && handle && handler();
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, handle, handler]);
}

module.export = useOutsideClickHandler