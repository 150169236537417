import USER from "./types";
export const userIsMobile = (payload) => {
    return {
        type: USER.IS_MOBILE,
        payload
    }
};
export const userSetAccountData = (payload) => {
    return {
        type: USER.SET_ACCOUNT_DATA,
        payload
    }
};
export const userUpdateData = (payload) => {
    return {
        type: USER.UPDATE_DATA,
        payload
    }
};
export const userGetBalance = (payload) => {
    return {
        type: USER.GET_BALANCE,
        payload
    }
};

export const userChangeConfig = (payload) => {
    return {
        type: USER.CHANGE_CONFIG,
        payload
    }
};

export const openNewBetController = (payload) => {
    return {
        type: USER.OPEN_NEW_BET_CONTROLLER,
        payload
    }
};
export const closeBetController = (payload) => {
    return {
        type: USER.CLOSE_BET_CONTROLLER,
        payload
    }
};

export const userSetControllers = (payload) => {
    return {
        type: USER.SET_CONTROLLERS,
        payload
    }
};
export const userCashOutAccepted = (payload) => {
    return {
        type: USER.CASH_OUT_ACCEPTED,
        payload
    }
};
export const userOpenTermAndConditionModal = () => {
    return {
        type: USER.OPEN_TERM_AND_CONDITION_MODAL,
    }
};
export const userOpenProvablyFairnessModal = () => {
    return {
        type: USER.OPEN_PROVABLY_FAIRNESS_MODAL,
    }
};
export const userOpenHonestyModal = (payload) => {
    return {
        type: USER.OPEN_HONESTY_MODAL,
        payload
    }
};
export const userCloseModal = () => {
    return {
        type: USER.CLOSE_MODAL
    }
};
export const userOpenAllBetsModal = () => {
    return {
        type: USER.OPEN_ALL_BETS_MODAL
    }
};
export const userOpenHistoryModal = () => {
    return {
        type: USER.OPEN_HISTORY_MODAL
    }
};
export const userOpenSettingsModal = () => {
    return {
        type: USER.OPEN_SETTINGS_MODAL
    }
};
export const userOpenTournamentModal = () => {
    return {
        type: USER.OPEN_TOURNAMENT_MODAL
    }
};
export const userOpenPromoModal = () => {
    return {
        type: USER.OPEN_PROMO_MODAL
    }
};
export const userOpenMobileMenu = () => {
    return {
        type: USER.OPEN_MOBILE_MENU
    }
};
export const userCloseMobileMenu = () => {
    return {
        type: USER.CLOSE_MOBILE_MENU
    }
};

export const userBet = (payload) => {
    return {
        type: USER.BET,
        payload
    }
};


export const userCancelBet = (payload) => {
    return {
        type: USER.CANCEL_BET,
        payload
    }
};

export const userCashOut = (payload) => {
    return {
        type: USER.CASH_OUT,
        payload
    }
};


export const updateDemoBalance = (payload) => {
    return {
        type: USER.UPDATE_DEMO_BALANCE,
        payload
    }
};

export const userDisconnectSocket = () => {
    return {
        type: USER.DISCONNECT_SOCKET
    }
};
export const userToggleDemo = () => {
    return {
        type: USER.TOGGLE_DEMO,
    };
};
export const userResetBetControllers = () => {
    return {
        type: USER.RESET_BET_CONTROLLERS,
    };
};
export const userResetBetController = (payload) => {
    return {
        type: USER.RESET_BET_CONTROLLER,
        payload
    };
};

export const userToggleAutoBet = (payload) => {
    return {
        type: USER.TOGGLE_AUTO_BET,
        payload
    }
};

export const userToggleAutoCashOut = (payload) => {
    return {
        type: USER.TOGGLE_AUTO_CASH_OUT,
        payload
    }
};

export const userClearCashes = async () => { //it is not unused
    let cashes = await caches.keys()
    cashes.forEach((name) => {
        caches.delete(name);
    });
    window.location.reload();
};