export const detectMobile = () => {
    return {
        isMobile: window.innerWidth < 641,
        isMobileLandscape:  (window.innerHeight < window.innerWidth &&  window.innerHeight < 641)
    }
};
export const convertAmountByUserRate = (amount, rate) => {
    let val = (amount / rate).toFixed(2);
    return val === '0.00' ? '-' : val
};

export const shuffleArray = (array) => {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[i]];
    }
    return shuffledArray;
}