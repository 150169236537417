import {useSelector} from "react-redux";
import Modal from "./Modal";
import ModalBody from "./ModalBody/ModalBody";
import ModalHeader from "./ModalHeader/ModalHeader";
import {useTranslation} from "react-i18next";

function ProvablyFairnessModal() {
    const isProvablyFairnessModalOpen = useSelector(state => (state.user.modal.isProvablyFairnessModalOpen));
    const isMobile = useSelector(state => (state.user.isMobile));
    const isMobileLandscape = useSelector(state => (state.user.isMobileLandscape));
    const {t} = useTranslation()


    if (!isProvablyFairnessModalOpen) return null
    return (
        <Modal isOpen={isProvablyFairnessModalOpen}>
            {(isMobile && !isMobileLandscape) && <ModalHeader/>}
            <ModalBody>
                <div className="popup__title">{t("modals.proof_modal.title")}</div>
                <div className="popup__paragraph">{t("modals.proof_modal.paragraph1")}</div>
                <div className="popup__paragraph">{t("modals.proof_modal.paragraph2")}
                    <a className="popup__link" target="_blank" rel="noopener noreferrer"
                       href="https://sha512.online/">https://sha512.online/</a>:
                </div>
                <div className="popup__paragraph">{t("modals.proof_modal.paragraph3")}</div>
                <div className="popup__paragraph">{t("modals.proof_modal.paragraph4")}</div>
                <div className="popup__subtitle">{t("modals.proof_modal.subtitle")}</div>
                <div className="popup__paragraph">{t("modals.proof_modal.paragraph5")}</div>
            </ModalBody>
        </Modal>
    )
}

export default ProvablyFairnessModal