import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ImgGenerator from "./RandomElements/RandomImageGenerator";
import CloudGenerator from "./Clouds/CloudGenerator";
import StarGenerator from "./Stars/StarGenerator";
import Balloon from "./Balloon/Balloon";
import BettingTimer from "./BettingTimer/BettingTimer";
import Background from "./Background/Background";
import {userResetBetControllers, userToggleDemo} from "../../../actions/user/actions";
import {useTranslation} from "react-i18next";
import Notification from "../Notification/Notification";

const Animation = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const state = useSelector(state => state.game.round.state);
    const id = useSelector(state => state.game.round.id);
    const animation = useSelector(state => state.game.round.animation);
    const isMobileLandscape = useSelector(state => state.user.isMobileLandscape);
    const betControllers = useSelector(state => state.user.betControllers);
    const isDemoActive = useSelector(state => state.user.isDemoActive);
    const notification = useSelector(state => state.game.notification)

    const [roundState, setRoundState] = useState(null);
    const [animate, setAnimate] = useState(false);
    const [roundId, setRoundId] = useState(null);

    useEffect(() => {
        if (id !== roundId) {
            return setRoundId(id)
        }
    }, [id, roundId]);

    useEffect(() => {
        if (roundState !== state) {
            if (roundState === 'playing' && state === 'crashed') {
                pauseAllAnimations(true)
            }

            if (roundState === 'crashed' && state === 'betting') {
                pauseAllAnimations(false)
                setAnimate(false)
            }

            if (animation && !animate) {
                setAnimate(true)
            }

            setRoundState(state)
        }
    }, [state, roundState, animation, animate]);


    const pauseAllAnimations = (stop) => {
        const el = document.querySelector(".animation")
        if (stop) {
            el.classList.add('pause-animations')
        } else {
            el.classList.remove('pause-animations');
        }

    }

    const toggleDemo = () => {
        const isBetExists = Object.values(betControllers).filter(c => c.roundId !== null).length;
        if (!isDemoActive && isBetExists) return;
        dispatch(userResetBetControllers())
        dispatch(userToggleDemo())
    }

    return <>
        <div className="animation animation__content">
            <div
                className={`animation__wrapper ${(isMobileLandscape && !Object.values(betControllers).some(item => !item.isActive)) ? "animation__wrapper--small" : ''}`}>
                <Background isMoving={animate}/>
                {animate && <StarGenerator/>}
                {
                    animate && <>
                        <CloudGenerator/>
                        <ImgGenerator/>
                    </>
                }
                <BettingTimer/>
                <Balloon/>
                {
                    isDemoActive && <div
                        className={`animation__real-game-btn`}
                        onClick={() => toggleDemo()}
                    >
                        <span className="animation__real-game-btn--text">{t('animation.realButton')}</span>
                        <span className="animation__real-game-btn--big"></span>
                    </div>
                }
            </div>
            {notification.status && <Notification message={notification.message} status={notification.status}/>}
            {/*<Notification message={notification.message} status={notification.status}/>*/}
        </div>
    </>

}
export default Animation;