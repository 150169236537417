const types = {
    SET_ACCOUNT_DATA: 'userSetAccountData',
    UPDATE_DATA: 'userUpdateData',
    GET_BALANCE: 'userGetBalance',
    CHANGE_CONFIG: 'userChangeConfig',
    SET_CONTROLLERS: 'userSetControllers',
    CASH_OUT: 'userCashOut',
    CASH_OUT_ACCEPTED: 'userCashOutAccepted',
    BET: 'userBet',
    CANCEL_BET: 'userCancelBet',
    UPDATE_DEMO_BALANCE: 'updateDemoBalance',
    DISCONNECT_SOCKET: 'userDisconnectSocket',
    RESET_BET_CONTROLLERS: 'userResetBetControllers',
    RESET_BET_CONTROLLER: 'userResetBetController',
    OPEN_TOURNAMENT_MODAL: 'userOpenTournamentModal', //only client side
    OPEN_TERM_AND_CONDITION_MODAL: 'userOpenTermAndConditionModal', //only client side
    OPEN_PROVABLY_FAIRNESS_MODAL: 'userOpenProvablyFairnessModal',//only client side
    OPEN_HONESTY_MODAL: 'userOpenHonestyModal',//only client side
    OPEN_PROMO_MODAL: 'userOpenPromoModal',//only client side
    CLOSE_MODAL: 'userCloseModal',//only client side
    OPEN_ALL_BETS_MODAL: 'userOpenAllBetsModal',//only client side
    OPEN_HISTORY_MODAL: 'userOpenHistoryModal',//only client side
    OPEN_SETTINGS_MODAL: 'userSettingsModal',//only client side
    OPEN_MOBILE_MENU: 'userOpenMobileMenu',//only client side
    CLOSE_MOBILE_MENU: 'userCloseMobileMenu',//only client side
    TOGGLE_DEMO: 'userToggleDemo',//only client side
    TOGGLE_AUTO_BET: 'userToggleAutoBet',//only client side
    TOGGLE_AUTO_CASH_OUT: 'userToggleAutoCashOut',
    IS_MOBILE: 'userIsMobile', //only client side
    OPEN_NEW_BET_CONTROLLER: 'openNewBetController', //only client side
    CLOSE_BET_CONTROLLER: 'closeBetController', //only client side
}
export default types;