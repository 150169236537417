import {useRef, useState} from "react";
import PropTypes from "prop-types";
import OutsideClickHandler from "../OutsideClickHandler/OutsideClickHandler";

const DropDown = ({icon = "settings", items, onChange}) => {
    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef();
    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const onChangeHandler = (value) => {
        onChange && onChange(value);
    }

    return (
        <OutsideClickHandler handle={isOpen} handler={() => toggleDropdown()}>
            <div className="dropdown dropdown__wrapper" ref={ref}>
                <i className={`dropdown__icon dropdown__icon--${icon} ${isOpen ? `dropdown__icon--${icon}-active` : ''}`}
                   onClick={toggleDropdown}/>
                {isOpen && <div className="dropdown__list">
                    {items.map((item, index) => (
                        <div key={index+"dropdownItem"} className={`dropdown__list--item`}
                             onClick={() => item.onChange ? item.onChange(item.value) : onChangeHandler(item.value)}>{item.component}</div>
                    ))}
                </div>}
            </div>
        </OutsideClickHandler>
    );
}

DropDown.propTypes = {
    items: PropTypes.array.isRequired
};

export default DropDown