import {useTranslation} from 'react-i18next';

const Tabs = (props) => {
    const {toggle, selected, tabItems} = props;
    const {t} = useTranslation();
    return <div className={`table__select table__select--${tabItems.length}`}>
        {tabItems.map((item, index) => {
            return <div className='table__select__item' onClick={() => toggle(index)} key={item + index}>{t(item)}</div>
        })}
        <span className={`toggle active-${selected}`}></span>
    </div>
}
export default Tabs