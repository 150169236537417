import ControlContainer from "../ControlContainer/ControlContainer";
import MobileHistory from "../MobileHistory/MobileHistory";
import {useSelector} from "react-redux";
import Animation from "../Animation/Animation";

function Game() {
    const {isMobile, isMobileLandscape} = useSelector(state => state.user);
    return (
        <div className="game game__wrapper">
            <Animation/>
            {(isMobile && !isMobileLandscape) && <MobileHistory/>}
            <ControlContainer/>
        </div>
    )
}

export default Game