import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const AmountButtons = React.memo(({handleClick, currentBet, maxBet, disabled}) => {
    const {buttonValues, balance, demoBalance, isDemoActive} = useSelector(state => (state.user));
    const [selectedButtonKey, setSelectedButtonKey] = useState(null);
    const {t} = useTranslation();
    const handleBtnClick = ({key, value}) => {
        if(value === 'all_in') {
            handleAllIn()
        } else {
            currentBet = parseFloat(currentBet);
            let result;
            if (key === selectedButtonKey) {
                result = currentBet < value ? value : currentBet + value > maxBet ? maxBet : currentBet + value;
            } else {
                result = value
            }
            setSelectedButtonKey(key);
            result = result.toFixed(2)
            handleClick(result)
        }
    }

    const handleAllIn = () => {
        let allInAmount = isDemoActive ? demoBalance : balance;
        let result = allInAmount > maxBet ? maxBet : allInAmount.toFixed(2);
        handleClick(result)
    }

    return <div className={`controlItem__left--btn ${disabled}`}>
        {
            buttonValues && Object.values(buttonValues).map(((value, index) => {
                const name =( value === 'all_in') ? t('control.all_in') : value;
                return <button
                    key={`${value}${index}${Math.random()}`}
                    onClick={() => handleBtnClick({key: index, value})}
                    className="controlItem__left--btn--item"
                > {name} </button>
            }))
        }
    </div>
}, (prevProps, nextProps) => {
    return (prevProps.currentBet === nextProps.currentBet) && (prevProps.disabled === nextProps.disabled)
})

export default AmountButtons
