import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { convertAmountByUserRate } from "../../../../../utilities/utilities";
import { useCallback, useEffect, useState } from "react";
import { userBet, userCashOut, userCancelBet, userToggleAutoBet, userToggleAutoCashOut } from "../../../../../actions/user/actions";
import useDebounce from "../../../../../CustomHooks/DebounceClick";

function BetButton({ controllerData }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const gameLoader = useSelector(state => state.game.gameLoader);
    const currentRound = useSelector(state => state.game.round);
    const { currencyRate, isDemoActive } = useSelector(state => state.user);
    const { id: controllerId, roundId, transactionId, amount, autoBet, autoCashOut, betAmount, autoCashOutValue } = controllerData;
    const [state, setState] = useState('');
    const [localMultiplier, setMultiplier] = useState(null);
    const [isAutoCashOutCalled, setAutoCashOutCalled] = useState(false);
    const [nextAutoBet, setNextAutoBet] = useState(true);

    const cashOut = useCallback((multiplier = null) => {
        if (gameLoader) return;
        dispatch(userCashOut({
            bettorId: controllerId,
            cashOutValue: multiplier,
            isDemoActive: isDemoActive,
            isAutoCashOut: autoCashOut,
        }));
        autoBet && setNextAutoBet(false);
    }, [dispatch, controllerId, isDemoActive, gameLoader, autoCashOut, autoBet]);

    const debouncedCashOut = useDebounce(cashOut);

    const betOnBox = useCallback(() => {
        if (gameLoader) return;
        dispatch(userBet({
            amount: betAmount,
            bettorId: controllerId,
            isDemoActive: isDemoActive,
            isAutoBet: autoBet
        }));
    }, [dispatch, betAmount, controllerId, isDemoActive, gameLoader, autoBet]);

    const debouncedBetOnBox = useDebounce(betOnBox);

    const cancelBet = useCallback(() => {
        if (gameLoader) return;

        dispatch(userCancelBet({
            bettorId: controllerId,
            transactionId: transactionId,
            isDemoActive: isDemoActive,
            roundId
        }));

        autoBet && dispatch(userToggleAutoBet({ bettorId: controllerId }));
        autoCashOut && dispatch(userToggleAutoCashOut({ bettorId: controllerId }));

    }, [dispatch, controllerId, transactionId, gameLoader, isDemoActive, autoBet, autoCashOut, roundId]);

    const debouncedCancelBet = useDebounce(cancelBet);

    const checkMultiplierInRange = useCallback(() => {
        return (
            parseFloat(localMultiplier) <= parseFloat(autoCashOutValue) &&
            parseFloat(currentRound.multiplier) >= parseFloat(autoCashOutValue)
        );
    }, [localMultiplier, autoCashOutValue, currentRound]);

    const handleCashOut = useCallback(() => {
        if (currentRound.crashedMultiplier) return;
        debouncedCashOut(currentRound.multiplier);
    }, [currentRound, debouncedCashOut]);

    useEffect(() => {
        if (checkMultiplierInRange() && !isAutoCashOutCalled && autoCashOut && roundId === currentRound.id) {
            setAutoCashOutCalled(true);
            debouncedCashOut(autoCashOutValue);
        }
        if (currentRound.multiplier) {
            return setMultiplier(currentRound.multiplier)
        }

    }, [currentRound.multiplier, roundId, currentRound.id, localMultiplier, autoCashOut, debouncedCashOut, checkMultiplierInRange, isAutoCashOutCalled, autoCashOutValue]);

    useEffect(() => {
        if (state !== currentRound.state) {
            if (state === 'crashed' && currentRound.state === 'betting') {
                autoBet && debouncedBetOnBox();
                isAutoCashOutCalled && setAutoCashOutCalled(false);
                setMultiplier(null);
            }
            setState(currentRound.state);
        }

    }, [currentRound.state, state, autoBet, debouncedBetOnBox, isAutoCashOutCalled]);

    useEffect(() => {
        if (autoBet && !controllerData.transactionId && nextAutoBet) {
            autoBet && debouncedBetOnBox();
            setAutoCashOutCalled(false);
        }
        if (!autoBet) {
            setNextAutoBet(true)
        }
    }, [autoBet, controllerData.transactionId, roundId, currentRound.id, nextAutoBet, debouncedBetOnBox])

    return (
        <div className="controlItem__right__bet">
            {
                !roundId && <button className="controlItem__right__bet--btn" onClick={debouncedBetOnBox}>
                    <span className="controlItem__right__bet--btn--text">
                        {currentRound.multiplier === 0 ? t("control.bet_btn") : t("control.bet_next_btn")}
                    </span>
                </button>
            }

            {
                ((roundId && currentRound.id !== roundId) || ((roundId && currentRound.id === roundId && currentRound.multiplier === 0) && currentRound.bettingTime > 1)) &&
                <button className="controlItem__right__bet--btn orange" onClick={() => debouncedCancelBet()}>
                    <span className="controlItem__right__bet--btn--text">{t("control.bet_cancel")}</span>
                </button>
            }

            {
                (roundId && currentRound.id === roundId && currentRound.multiplier === 0) && currentRound.bettingTime <= 1 &&
                <button className="controlItem__right__bet--btn" disabled={true}>
                    <span className="controlItem__right__bet--btn--text">{t("control.bet_accepted")}</span>
                </button>
            }

            {
                currentRound.id === roundId && currentRound.multiplier !== 0 &&
                <button
                    className={`controlItem__right__bet--btn ${!currentRound.crashedMultiplier ? 'green' : 'red'}`}
                    onClick={() => handleCashOut()}>

                    {
                        !currentRound.crashedMultiplier ? <>
                            <span className="controlItem__right__bet--btn--text">{t("control.cashOut_btn")}</span>
                            <div
                                className="controlItem__right__bet--btn--cashOut-amount">{(convertAmountByUserRate(amount, currencyRate) * currentRound.multiplier).toFixed(2)}
                            </div>
                        </> : <span className="controlItem__right__bet--btn--text">{t("control.bet_next_btn")}</span>
                    }

                </button>
            }
        </div>
    )
}

export default BetButton;
