import GAME from "../actions/game/types";

const initialState = {
    gameLoader: true,
    round: {
        bettingTime: null,
        multiplier: null,
        crashedMultiplier: null,
        betList: [],
        state: 'start',
        animation: false
    },
    betHistory: [],
    finishedRounds: [],
    highestMultiplierRounds: [],
    isAnimationActive: true,
    notification: {}
};
const reducer = (state = initialState, action) => {
    switch (action?.type) {
        case  GAME.SET_LOADER:
            return {
                ...state,
                ...action.payload
            }
        case  GAME.FINISHED_ROUNDS:
            return {
                ...state,
                finishedRounds: action.payload.finishedRounds
            }

        case  GAME.HIGHEST_MULTIPLIERS:
            return {
                ...state,
                highestMultiplierRounds: action.payload.highestMultiplierRounds
            }
        case  GAME.UPDATE:
            return {
                ...state,
                ...action.payload
            }

        case  GAME.ROUND_MULTIPLIER:
            return {
                ...state,
                round: {
                    ...state.round,
                    timestamp: action.payload.timestamp,
                    multiplier: action.payload.multiplier,
                    state: action.payload.state,
                    animation: action.payload.animation
                }
            }
        case  GAME.ROUND_BETTING_TIMER:
            return {
                ...state,
                round: {
                    ...state.round,
                    bettingTime: action.payload.bettingTime,
                    state: action.payload.state,
                    animation: action.payload.animation
                }
            }
        case  GAME.ROUND_UPDATE:
            return {
                ...state,
                round: {
                    ...state.round,
                    ...action.payload
                }
            }
        case  GAME.ROUND_PLAYER_LIST:
            return {
                ...state,
                round: {
                    ...state.round,
                    betList: action.payload.betList
                }
            }

        case  GAME.ROUND_CRASH:
            return {
                ...state,
                round: {
                    ...state.round,
                    crashedMultiplier: action.payload.crashedMultiplier,
                    timestamp: action.payload.timestamp,
                    state: action.payload.state,
                }
            }

        case GAME.TOGGLE_ANIMATION:
            return {
                ...state,
                isAnimationActive: !state.isAnimationActive
            }

        case GAME.NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            }

        case GAME.RESET_NOTIFICATION:
            return {
                ...state,
                notification: {}
            }

        default:
            return state;
    }
};
export default reducer;
