import {useEffect, useState} from "react";
import Loader from "../Loaders/Spinner";
import Tabs from "../Tabs/Tabs";
import MappedTables from "../Tables/tablemapping";
import Promo from "../Promo/Promo";

function TableContainer(props) {
    const {tabItems, tableData} = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [initialTabItems, setInitialTabItems] = useState(tabItems);
    const handleTabChange = (newTabIndex) => {
        setSelectedTabIndex(newTabIndex);
    };

    useEffect(() => {
        if (tabItems.length !== initialTabItems.length) {
            setSelectedTabIndex(0);
            setInitialTabItems(tabItems)
        }
    }, [tabItems.length, initialTabItems.length, tabItems]);

    const TableComponent = MappedTables[tableData[selectedTabIndex].role];

    return (
        <div className="table table__wrapper">
            <Tabs
                toggle={handleTabChange}
                selected={selectedTabIndex + 1}
                tabItems={initialTabItems}
            />
            {
                tabItems[selectedTabIndex] === 'tabs.information' ?
                    <Promo/> :
                    tableData?.length > 0 && tableData[selectedTabIndex]?.tableRows?.length > 0 ?
                        <TableComponent data={tableData[selectedTabIndex]}/> : <Loader/>
            }

        </div>
    )
}

export default TableContainer