import combinedActions from '../actions/combinedActions'
import {gameSendNotification, setLoader} from "../actions/game/actions";
class UserSockets {
    constructor() {
        this.socket = {}
    }
    onConnect(socket) {
        this.socket = socket;
        // console.log('client connected to game socket userSocket_line-5');
    }
    disconnectSocket(dispatch) {
        dispatch(setLoader({gameLoader: true}))
        dispatch(gameSendNotification({type: 'error' , message: "reload_page", labelFor : 'loader'}))
        // console.log('client disconnected from game socket userSocket_line-21');
    }

    onConnectionError(dispatch, error = 'connection_error') {
        dispatch(setLoader({gameLoader: true}))
        dispatch(gameSendNotification({type: 'error' , message: "connection_error", labelFor : 'loader'}))
    }
    handleGameSocketEvents(data, dispatch) {
        const {action, payload, isDispatch} = data;
         if(isDispatch) {
             dispatch(combinedActions[action](payload))
         }else {
             combinedActions[action](payload)
         }
    }

}
const UserSocket = new UserSockets()
export default UserSocket ;
