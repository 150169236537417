import {useSelector} from "react-redux";
import MobileHistoryItem from "./MobileHistoryItem";

function MobileHistory() {
    const roundHistory = useSelector(state => state.game.finishedRounds);
    const roundMultipliers = roundHistory.tableRows.map(item => item.cells["table_odd"])

    return (
        <div className="mobile__history">
            <div className="mobile__history__wrapper">
                {roundMultipliers.map((multiplier) => {
                    return <MobileHistoryItem key={multiplier + Math.random()} data={multiplier}/>
                })}
            </div>
        </div>
    )
}

export default MobileHistory