import {useSelector} from "react-redux";
import Modal from "./Modal";
import CopyButton from "../CopyButton/CopyButton";
import ModalBody from "./ModalBody/ModalBody";
import ModalHeader from "./ModalHeader/ModalHeader";
import {useTranslation} from "react-i18next";

function HonestyModal() {
    const {isOpen, salt, hash, odd, roundId} = useSelector(state => (state.user.modal.honestyModal));
    const isMobile = useSelector(state => (state.user.isMobile));
    const isMobileLandscape = useSelector(state => (state.user.isMobileLandscape));
    const {t} = useTranslation()

    return (
        <Modal isOpen={isOpen}>
            {(isMobile && !isMobileLandscape) && <ModalHeader/>}
            <ModalBody>
                <div className="popup__title--wrapper">
                    <div className="popup__title">{t("modals.honesty_modal.title")} №{roundId}</div>
                    <div className="honesty__game-result">
                        <span className="honesty__game-result--label">{t("modals.honesty_modal.result")}</span>
                        <span className="honesty__game-result--odd">{odd}</span>
                    </div>
                </div>
                <div className="honesty__container-wrapper">
                    <div className="popup__subtitle honesty__subtitle">
                        <i className="honesty__icon honesty__icon--hash"></i>Hash SHA512
                    </div>
                    <div className="popup__paragraph">{t("modals.honesty_modal.paragraph1")}</div>
                    <div className="honesty__info-field">
                        {hash}
                        <CopyButton value={hash}/>
                    </div>
                </div>
                <div className="honesty__container-wrapper">
                    <div className="popup__subtitle honesty__subtitle">
                        <i className="honesty__icon honesty__icon--salt"></i>Salt
                    </div>
                    <div className="popup__paragraph">{t("modals.honesty_modal.paragraph2")}</div>
                    <div className="honesty__info-field">{salt}
                        <CopyButton value={salt}/>
                    </div>
                </div>
                <div className="honesty__container-wrapper">
                    <div className="popup__subtitle honesty__subtitle">
                        <i className="honesty__icon honesty__icon--formula"></i>{t("modals.honesty_modal.subtitle")}
                    </div>
                    <div
                        className="honesty__info-field honesty__info-field--formula honesty__info-field--color">{`Hash = SHA512(Salt + ${t("modals.honesty_modal.result")})`}
                    </div>
                    <div className="popup__paragraph">{t("modals.honesty_modal.paragraph3")}
                        <a className="popup__link" target="_blank" rel="noopener noreferrer"
                           href="https://sha512.online/">https://sha512.online/</a>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default HonestyModal