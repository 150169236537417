import TOURNAMENT from "../actions/tournament/types";

const initialState = {
    description: [],
    userPlace: {},
    leaderBoard: [],
    hasTournament: false
};

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case TOURNAMENT.GET_FULL_DATA:
            return {
                ...state,
                ...action.payload
            };

        case TOURNAMENT.UPDATE_LEADERBOARD:
            return {
                ...state,
                ...action.payload
            };

        case TOURNAMENT.UPDATE:
            return {
                ...state,
                ...action.payload
            };

        case TOURNAMENT.CLEAR_TOURNAMENT:
            return {
                description: [],
                userPlace: {},
                leaderBoard: [],
                hasTournament: false
            };


        default:
            return state;
    }
};

export default reducer;
