import React from 'react';

const Switcher = React.memo(({onToggle, isActive, direction = 'horizontal'}) => {
    return (
        <div className={`switch switch__${direction} switch__${direction}--${isActive ? "enabled" : "disabled"}`}
             onClick={onToggle}></div>
    )
}, (prevProps, nextProps) => {
    return prevProps.isActive === nextProps.isActive
})

export default Switcher
