import Controller from "./Controller/Controller";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {openNewBetController} from "../../../actions/user/actions";

function ControlContainer() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const {1: controller1, 2: controller2} = user.betControllers;
    const isAllControllersActive = controller1.isActive && controller2.isActive;
    const openNewBox = () => {
        const disabledBetControllerId = controller1.isActive ? controller2.id : controller1.id;
        return dispatch(openNewBetController({id: disabledBetControllerId}));
    };
    return (
        <div className="control control__wrapper">
            <div className="control__controlItems__wrapper">
                {controller1.isActive &&
                    <Controller controllerData={{...controller1, hasCloseBtn: isAllControllersActive}}/>
                }
                {controller2.isActive &&
                    <Controller controllerData={{...controller2, hasCloseBtn: isAllControllersActive}}/>}
            </div>
            {
                !isAllControllersActive && <div className="control__add" onClick={() => openNewBox()}>
                    <div className="control__add--btn"></div>
                    <label className="control__add--text">{t('control.add_text')}</label>
                </div>
            }
        </div>
    )
}

export default ControlContainer
