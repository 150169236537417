import {useSelector} from "react-redux";
import Modal from "./Modal";
import ModalBody from "./ModalBody/ModalBody";
import ModalHeader from "./ModalHeader/ModalHeader";
import {useTranslation} from "react-i18next";

function TermAndConditionsModal() {
    const isTermAndConditionModalOpen = useSelector(state => (state.user.modal.isTermAndConditionModalOpen));
    const {minOdd, maxOdd} = useSelector(state => (state.user.oddRange));
    const {minBet, maxBet,maxWin} = useSelector(state => (state.user.betRange));
    const currencyCode = useSelector(state => state.user.currencyCode);
    const isMobile = useSelector(state => (state.user.isMobile));
    const isMobileLandscape = useSelector(state => (state.user.isMobileLandscape));
    const {t} = useTranslation();

    if (!isTermAndConditionModalOpen) return null
    return (
        <Modal isOpen={isTermAndConditionModalOpen}>
            {(isMobile && !isMobileLandscape) && <ModalHeader/>}
            <ModalBody>
                <div className="popup__title">{t("modals.rules_modal.title")}</div>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph1")}</div>
                <div className="popup__subtitle">{t("modals.rules_modal.subtitle")}</div>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph2")}</div>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph3")}</div>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph4")}</div>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph5")}</div>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph6")}</div>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph7")}</div>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph8")}</div>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph9")}</div>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph10")}</div>
                <ul className="popup__list">
                    <li>{t("modals.rules_modal.list_item1")} {minBet} {currencyCode}</li>
                    <li>{t("modals.rules_modal.list_item2")} {maxBet} {currencyCode}</li>
                    <li>{t("modals.rules_modal.list_item3")} {minOdd}</li>
                    <li>{t("modals.rules_modal.list_item4")} {minOdd}</li>
                    <li>{t("modals.rules_modal.list_item5")} {maxOdd}</li>
                    <li>{t("modals.rules_modal.list_item6")} {`${maxWin} ${currencyCode}`}</li>
                </ul>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph11")}</div>
                <div className="popup__paragraph">{t("modals.rules_modal.paragraph12")}</div>
            </ModalBody>
        </Modal>
    )
}

export default TermAndConditionsModal