const types = {
    EVENT: 'gameEvent',
    NOTIFICATION: 'gameSendNotification',
    SET_LOADER: 'setLoader',
    UPDATE: 'gameUpdateState',
    FINISHED_ROUNDS: 'gameFinishedRounds',
    HIGHEST_MULTIPLIERS: 'gameHighestMultipliers',
    ROUND_PLAYER_LIST: 'gameRoundSetPlayerList',
    ROUND_UPDATE: 'gameRoundUpdateState',
    ROUND_BETTING_TIMER: 'gameRoundSetBettingTime',
    ROUND_MULTIPLIER: 'gameRoundSetMultiplier',
    ROUND_CRASH: 'gameRoundCrash',
    TOGGLE_ANIMATION: 'gameToggleAnimation',// only client side
    RESET_NOTIFICATION: 'gameResetNotification' //only client side
};
export default types
