import React from 'react';
import FadeNumber from "../FadeNumber/FadeNumber";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const BettingTimer = () => {
    const {t} = useTranslation();
    const  bettingTime = useSelector(state => state.game.round.bettingTime);
    const isAnimationActive = useSelector(state => state.game.isAnimationActive);

    return  <>
        {
            bettingTime > 0 && isAnimationActive && <FadeNumber number={bettingTime}/>
        }
        {
            bettingTime > 0 && !isAnimationActive && <>
                <div className='animation__disabled-betting-timer-title'>{t('animation.betting_time')}</div>
                <div className='animation__disabled-betting-timer'>{bettingTime}</div>
            </>
        }

    </>
};

export default BettingTimer;
