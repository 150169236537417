import TOURNAMENT from "./types";
export const tournamentGetFullData = (payload) => {
    return {
        type: TOURNAMENT.GET_FULL_DATA,
        payload
    }
};
export const tournamentUpdateLeaderBoard = (payload) => {
    return {
        type: TOURNAMENT.UPDATE_LEADERBOARD,
        payload
    }
};
export const tournamentUpdate = (payload) => {
    return {
        type: TOURNAMENT.UPDATE,
        payload
    }
};
export const tournamentClearData = () => {
    return {
        type: TOURNAMENT.CLEAR_TOURNAMENT,
    }
};
