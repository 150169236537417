import {useDispatch, useSelector} from "react-redux";
import {
    userCloseMobileMenu,
    userOpenAllBetsModal, userOpenHistoryModal,
    userOpenProvablyFairnessModal, userOpenSettingsModal,
    userOpenTermAndConditionModal, userOpenTournamentModal
} from "../../../actions/user/actions";
import {useTranslation} from "react-i18next";
function MobileMenu() {

    const isMobileMenuOpen = useSelector(state => state.user.isMobileMenuOpen);
    const isMobile = useSelector(state => state.user.isMobile);
    const isMobileLandscape = useSelector(state => state.user.isMobileLandscape);
    const hasTournament = useSelector(state => state.tournament.hasTournament);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const exitFromGame = () => {
        window.parent.postMessage({action: 'goHome'}, '*')
    }

    return ((isMobileMenuOpen && isMobile && !isMobileLandscape) && <div className="mobile-menu">
            <div className="mobile-menu__wrapper">
                <div className="mobile-menu__top" onClick={() => dispatch(userCloseMobileMenu())}>
                    <div className="mobile-menu__close--btn"></div>
                    <span className="mobile-menu__close--text">{t("mobile_menu.close")}</span>
                </div>
                <div className="mobile-menu__bottom">
                    <ul className="mobile-menu__list">
                        {
                            hasTournament && <li className="mobile-menu__list--item"
                                onClick={() => dispatch(userOpenTournamentModal())}>{t("mobile_menu.tournament")}</li>
                        }
                        <li className="mobile-menu__list--item"
                            onClick={() => dispatch(userCloseMobileMenu())}>{t("mobile_menu.play")}</li>
                        <li className="mobile-menu__list--item"
                            onClick={() => dispatch(userOpenProvablyFairnessModal())}>{t("mobile_menu.proof")}</li>
                        <li className="mobile-menu__list--item"
                            onClick={() => dispatch(userOpenTermAndConditionModal())}>{t("mobile_menu.rules")}</li>
                        <li className="mobile-menu__list--item"
                            onClick={() => dispatch(userOpenAllBetsModal())}>{t("mobile_menu.all_bets")}</li>
                        <li className="mobile-menu__list--item"
                            onClick={() => dispatch(userOpenHistoryModal())}>{t("mobile_menu.round_history")}</li>
                        <li className="mobile-menu__list--item"
                            onClick={() => dispatch(userOpenSettingsModal())}>{t("mobile_menu.settings")}</li>
                        <li className="mobile-menu__list--item"
                            onClick={() => exitFromGame()}>{t("mobile_menu.exit")}</li>
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default MobileMenu