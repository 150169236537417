import TableContainer from "../TableContainer/TableContainer";
import Game from "../Game/Game";
import {useSelector} from "react-redux";
function Main() {
    const currentRoundBets =  useSelector(state => state.game.round.betList);
    const betHistory =  useSelector(state => state.game.betHistory);
    const roundHistory =  useSelector(state => state.game.finishedRounds);
    const highestMultiplierRounds =  useSelector(state => state.game.highestMultiplierRounds);
    const tournamentLeaderBoard = useSelector(state => state.tournament.leaderBoard);
    const hasTournament = useSelector(state => state.tournament.hasTournament);
    return (
        <div className="main main__wrapper">
            <div className="main__bets">
                <TableContainer
                    tabItems={['tabs.all_bets', 'tabs.my_bets']}
                    tableData={[currentRoundBets, betHistory]}
                />
            </div>
            <div className="main__game"><Game/></div>
            <div className="main__history">
                <TableContainer
                    tabItems={hasTournament ? ['tabs.round_history', 'tabs.round_history_top','tabs.tournament'] : ['tabs.round_history', 'tabs.round_history_top']}
                    tableData={[roundHistory, highestMultiplierRounds, tournamentLeaderBoard]}
                />
            </div>
        </div>
    )
}

export default Main