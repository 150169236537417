import {useDispatch, useSelector} from "react-redux";
import {userOpenTournamentModal} from "../../../actions/user/actions";
import {useTranslation} from "react-i18next";
import {convertAmountByUserRate} from "../../../utilities/utilities";

function MobileTournamentHeader() {
    const {isMobile, isMobileLandscape} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const {t} = useTranslation()

    const hasTournament = useSelector(state => state.tournament.hasTournament)
    const prize = useSelector(state => state.tournament.description.prize)
    const userRateValue = useSelector(state => state.user.currencyRate);
    const points = useSelector( state => state.tournament.userPlace.points)


    return ((isMobile && !isMobileLandscape && hasTournament) &&
        <div className="tournament__header" onClick={() => dispatch(userOpenTournamentModal())}>
            <div className="tournament__header__left"></div>
            <div className="tournament__header__center">
                <div className="tournament__header__center__wrapper">
                    <div className="tournament__header__center__item">
                        <span className="tournament__header__center__item--prize">{t('tournament.prize')}</span>
                        <span className="tournament__header__center__item--prize-pool">{convertAmountByUserRate(prize,userRateValue)}</span>
                    </div>
                    <div className="tournament__header__center__item">
                        <span className="tournament__header__center__item--score">{t('tournament.my_score')} </span>
                        <span className="tournament__header__center__item--points">{points}</span>
                    </div>
                </div>
            </div>
            <div className="tournament__header__right">
                <div className="tournament__header__right__wrapper">
                    <img className="tournament__header__right__icon" src="/img/tournament-icon.svg"
                         alt="tournament-icon" />
                </div>
            </div>
        </div>
    )
}

export default MobileTournamentHeader;
