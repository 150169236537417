import USER from "../actions/user/types";
import GAME from "../actions/game/types";
import UserSocket from "../sockets/userSocket";

const initialState = {
    id: null,
    platformId: null,
    lang: 'ru',
    currencyCode: null,
    currencyId: null,
    currencyRate: 1,
    balance: 0,
    demoBalance: 0,
    demoCurrency: 'DEMO',
    isMobile: false,
    isMobileLandscape: false,
    config: {},
    userName: '',
    betControllers: {
        1: {
            id: 1,
            isActive: true,
            roundId: null,
            amount: null,
            autoBet: false,
            autoCashOut: false,
            transactionId: null,
        },
        2: {
            id: 2,
            isActive: false,
            roundId: null,
            amount: null,
            autoBet: false,
            autoCashOut: false,
            transactionId: null,
        }
    },
    buttonValues: {},
    betRange: {
        minBet: 0,
        maxBet: 0,
        maxWin:0,
    },
    oddRange: {
        minOdd: 1.01,
        maxOdd: 5000,
    },
    modal: {
        isTermAndConditionModalOpen: false,
        isProvablyFairnessModalOpen: false,
        isAllBetsModalOpen: false,
        isHistoryModalOpen: false,
        isSettingsModalOpen: false,
        isTournamentModalOpen: false,
        isPromoModalOpen:false,
        honestyModal: {
            isOpen: false,
        },
    },
    isMobileMenuOpen: false,
    isDemoActive: false,
};

const reducer = (state = initialState, action) => {
    const socket = UserSocket.socket

    switch (action.type) {

        case USER.IS_MOBILE:
            return {
                ...state,
                isMobile: action.payload.isMobile,
                isMobileLandscape: action.payload.isMobileLandscape
            };
        case USER.SET_ACCOUNT_DATA:
            return {
                ...state,
                ...action.payload
            };
        case USER.UPDATE_DATA:
            return {
                ...state,
                ...action.payload
            };

        case USER.GET_BALANCE:
            return {
                ...state,
                balance: action.payload.balance
            };

        case  USER.CHANGE_CONFIG:
            socket.emit(GAME.EVENT, {action: action.type, payload: action.payload})
            return state

        case  USER.CLOSE_BET_CONTROLLER:
            return {
                ...state,
                betControllers: {
                    ...state.betControllers,
                    [action.payload.id]: {
                        ...state.betControllers[action.payload.id],
                        isActive: false,
                        autoBet: false,
                        autoCashOut: false
                    }
                }
            }

        case  USER.OPEN_NEW_BET_CONTROLLER:
            return {
                ...state,
                betControllers: {
                    ...state.betControllers,
                    [action.payload.id]: {
                        ...state.betControllers[action.payload.id],
                        isActive: true,
                        autoBet: false,
                        autoCashOut: false
                    }
                }
            }

        case  USER.CASH_OUT:
            socket.emit(GAME.EVENT, {action: action.type, payload: action.payload})
            return state

        case  USER.CANCEL_BET:
            socket.emit(GAME.EVENT, {action: action.type, payload: action.payload})
            return state

        case  USER.BET:
            socket.emit(GAME.EVENT, {action: action.type, payload: action.payload})
            return state

        case  USER.SET_CONTROLLERS:
            let betControllers = state.betControllers;
            if (action.payload.length === 0) {
                Object.keys(betControllers).forEach((key, index) => {
                    betControllers[key] = {
                        id: index + 1,
                        isActive: index === 0,
                        roundId: null,
                        amount: null,
                        transactionId: null,
                        autoBet: false,
                        autoCashOut: false
                    }
                })
            } else {
                action.payload.length > 0 && action.payload.forEach(bet => {
                    betControllers[bet.bettorId] = {
                        ...betControllers[bet.bettorId],
                        roundId: bet.roundId,
                        amount: bet.amount,
                        transactionId: bet.transactionId,
                        isActive: true
                    };
                })
            }
            return {
                ...state,
                betControllers,
            }

        case  USER.CASH_OUT_ACCEPTED:
            return {
                ...state,
                betControllers: {
                    ...state.betControllers,
                    [action.payload.bettorId]: {
                        ...state.betControllers[action.payload.bettorId],
                        roundId: null,
                        amount: null,
                        transactionId: null
                    }
                }
            }

        case USER.OPEN_PROVABLY_FAIRNESS_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    isProvablyFairnessModalOpen: true
                },
            }

        case USER.OPEN_TERM_AND_CONDITION_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    isTermAndConditionModalOpen: true
                },

            }
        case USER.OPEN_TOURNAMENT_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    isTournamentModalOpen: true
                },

            }

        case USER.OPEN_HONESTY_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    honestyModal: {
                        isOpen: true,
                        ...action.payload
                    }
                },

            }
        case USER.OPEN_PROMO_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    isPromoModalOpen: true
                },

            }

        case USER.CLOSE_MODAL:
            return {
                ...state,
                modal: {
                    isTermAndConditionModalOpen: false,
                    isProvablyFairnessModalOpen: false,
                    isAllBetsModalOpen: false,
                    isHistoryModalOpen: false,
                    isSettingsModalOpen: false,
                    isTournamentModalOpen: false,
                    isPromoModalOpen: false,
                    honestyModal: {
                        isOpen: false,
                    }
                }
            }

        case USER.UPDATE_DEMO_BALANCE:
            return {
                ...state,
                demoBalance: action.payload.amount
            }

        case USER.OPEN_ALL_BETS_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    isAllBetsModalOpen: true
                }
            }

        case USER.OPEN_HISTORY_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    isHistoryModalOpen: true
                }
            }

        case USER.OPEN_SETTINGS_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    isSettingsModalOpen: true
                }
            }

        case USER.OPEN_MOBILE_MENU:
            return {
                ...state,
                isMobileMenuOpen: true
            }

        case USER.CLOSE_MOBILE_MENU:
            return {
                ...state,
                isMobileMenuOpen: false
            }

        case USER.DISCONNECT_SOCKET:
            socket.emit(GAME.EVENT, {action: action.type})
            return state

        case USER.TOGGLE_DEMO:
            return {
                ...state,
                isDemoActive: !state.isDemoActive
            }

        case USER.RESET_BET_CONTROLLERS:
            socket.emit(GAME.EVENT, {action: action.type, payload: {}})
            return {
                ...state,
                betControllers: {
                    1: {
                        id: 1,
                        isActive: true,
                        roundId: null,
                        amount: null,
                        autoBet: false,
                        autoCashOut: false,
                        transactionId: null,
                    },
                    2: {
                        id: 2,
                        isActive: false,
                        roundId: null,
                        amount: null,
                        autoBet: false,
                        autoCashOut: false,
                        transactionId: null,
                    }
                }
            }

        case USER.RESET_BET_CONTROLLER:
            return {
                ...state,
                betControllers: {
                    ...state.betControllers,
                    [action.payload.bettorId]: {
                        ...state.betControllers[action.payload.bettorId],
                        roundId: null,
                        amount: null,
                        autoBet: false,
                        autoCashOut: false,
                        transactionId: null,
                    }
                }
            }

        case  USER.TOGGLE_AUTO_BET:
            return {
                ...state,
                betControllers: {
                    ...state.betControllers,
                    [action.payload.bettorId]: {
                        ...state.betControllers[action.payload.bettorId],
                        autoBet: !state.betControllers[action.payload.bettorId].autoBet,
                    }
                }
            }

        case  USER.TOGGLE_AUTO_CASH_OUT:
            return {
                ...state,
                betControllers: {
                    ...state.betControllers,
                    [action.payload.bettorId]: {
                        ...state.betControllers[action.payload.bettorId],
                        autoCashOut: !state.betControllers[action.payload.bettorId].autoCashOut,
                    }
                }
            }

        default:
            return state;
    }
};

export default reducer;
