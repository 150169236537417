import React, {useMemo} from 'react';
import {useSelector} from "react-redux";

const CloudGenerator = React.memo(() => {
    const isAnimationActive = useSelector(state => state.game.isAnimationActive);
    return useMemo(() => (
        <img src='/img/clouds/clouds.png' alt="clouds"
             className={`animation__clouds-img ${isAnimationActive ? '' : 'opacity-none'}`}/>
    ), [isAnimationActive]);
});

export default CloudGenerator;